@import 'antd/dist/reset.css';
@import './shared/styles/iterable.less';
@import './shared/styles/variables.less';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

html {
	box-sizing: border-box;
	font-size: 16px;
	height: 100%;
	width: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	height: 100%;
	width: 100%;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-family: 'Roboto', sans-serif;
	color: @text-color;
}

ol,
ul {
	list-style: none;
}

input,
textarea {
	font-size: 16px;
}

.ant-tag {
	font-size: 14px;
}

.ant-tooltip {
	.anticon-close {
		vertical-align: middle !important;
	}

	max-width: 300px;
	z-index: 10000;
}

.tags {
	.ant-tooltip-inner {
		padding-bottom: 0 !important;
	}
}

#root {
	height: 100%;
	width: 100%;
}

.ant-modal-content {
	.ant-modal-header {
		.ant-modal-title {
			font-weight: bold;
		}
	}

	.ant-modal-footer > .ant-btn {
		&:first-child :hover {
			color: @links-color;
			text-decoration: underline;
		}
	}
}

.ant-tooltip-inner {
	.ant-tag {
		margin-bottom: 5px;
	}
}

.ant-tabs-tab-active {
	div {
		font-weight: 400 !important;
		text-shadow: 0.2px 0 0 black !important;
		color: black !important;
	}
}

.ant-picker-ok span {
	text-transform: uppercase;
}

//disable singleChoice select class
.ant-select-disabled {
	border: 1px solid rgb(217, 217, 217);
	background-color: rgb(245, 245, 245);
	border-radius: 4px;
}

.color-white {
	color: #fff;
}

.margin-x-6 {
	margin: 0 6px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mt-0 {
	margin-top: 0;
}

.d-block {
	display: block;
}

.cursor-pointer {
	cursor: pointer;
}

.text-center {
	text-align: center;
}

.margin-left-1rem {
	margin-left: 1rem;
}

.default-font-size {
	font-size: 12px;
}

.mb-5 {
	margin-bottom: 5px;
}

.bg-gray {
	background-color: @color-gray;
}

.progressbar {
	margin-inline-end: 0;

	.ant-progress-text {
		.anticon-check-circle {
			&::after {
				content: '100%';
				color: #000;
			}

			svg {
				display: none;
			}
		}
	}
}

.bg-white {
	background-color: @color-white;
}

.margin-right-10 {
	margin-right: 10px;
}

.mt-10 {
	margin-top: 10px;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
}

.column {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	flex-direction: row;
}

.g-10 {
	gap: 10px;
}

.error {
	color: #e11e59;
}

.success {
	color: #31a619;
}

.uppercase {
	text-transform: uppercase;
}

.content {
	top: 40%;
	gap: 10px;
	display: flex;
	position: relative;
	flex-direction: column;

	.ant-spin {
		top: 0 !important;
	}
}

.capitalize {
	text-transform: capitalize;
}

.change-location-modal {
	.ant-modal-confirm-btns {
		display: flex;
		justify-content: flex-end;
		flex-direction: row-reverse;
		float: right;
		gap: 0.5rem;
	}
}

.justify-content-center {
	justify-content: center;
}

.space-between {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.hidden {
	display: none;
}

.w-600 {
	width: 800px;
}

.flex-1 {
	flex: 1;
}

.w-100 {
	width: 100%;
}

.btn-primary {
	color: @color-white;
	background-color: @color-primary-2;

	&:not(:disabled):hover {
		color: @color-white !important;
		border-color: @color-primary-1 !important;
		background-color: @color-primary-1;
	}
}

.disabled {
	color: @disabled-color;
	cursor: not-allowed;
}

.mc-height {
	height: max-content !important;
}

*::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

*::-webkit-scrollbar-thumb {
	height: 30%;
	width: 30%;
	background-color: #b3b3b3;
	border-radius: 10px;
}

.maskInput {
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.88);
	font-size: 14px;
	line-height: 1.5714285714285714;
	list-style: none;
	position: relative;
	display: inline-block;
	min-width: 0;
	background-color: #ffffff;
	background-image: none;
	border-width: 1px;
	border-style: solid;
	border-color: #d9d9d9;
	border-radius: 6px;
	transition: all 0.2s;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol', 'Noto Color Emoji';
}

.maskInput:hover {
	border-color: #4096ff;
}

.maskInput:focus-within {
	border-color: #1677ff;
	box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
	outline: 0;
}

.maskInput::placeholder {
	color: lightgrey;
}

@error-color: #f5222d;@border-radius-base: 4px;